<template>
  <div class="card">
    <div class="card-header">
      <h4>Person Information</h4>
    </div>
    <div class="card-body">
      <div class="form-group">
        <label>Name</label>
        <h5>{{ data.admin.username }}</h5>
      </div>
      <div class="form-group">
        <label>Role & Point</label>
        <h5>
          {{ data.admin.role.name }} / {{ data.admin.role.point_for_payroll }}
        </h5>
      </div>
      <div class="form-group">
        <label>Bank Account</label>
        <h5>{{ data.account_bank_name }}</h5>
      </div>
      <div class="form-group">
        <label>Account Name</label>
        <h5>{{ data.account_name }}</h5>
      </div>
      <div class="form-group">
        <label>Account Number</label>
        <h5>{{ data.account_number }}</h5>
      </div>
      <div class="form-group">
        <label>Month/Year</label>
        <h5>{{ data.month }}/{{ data.year }}</h5>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
};
</script>
